var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-post"},[_c('h1',[_vm._v("Dodaj wpisy")]),_c('el-form',{ref:"addPost",staticClass:"add-post-form",attrs:{"model":_vm.addPost,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Tytuł","prop":"title","rules":[
        { required: true, message: 'Tytuł jest wymagany'},
      ]}},[_c('el-input',{attrs:{"type":"title","autocomplete":"off"},model:{value:(_vm.addPost.title),callback:function ($$v) {_vm.$set(_vm.addPost, "title", $$v)},expression:"addPost.title"}})],1),_c('el-form-item',{attrs:{"label":"name","prop":"name","rules":[
        { required: true, message: 'nazwa jest wymagane'},
      ]}},[_c('el-input',{attrs:{"type":"name","autocomplete":"off"},model:{value:(_vm.addPost.name),callback:function ($$v) {_vm.$set(_vm.addPost, "name", $$v)},expression:"addPost.name"}})],1),_c('el-form-item',{attrs:{"label":"Treść","prop":"content","rules":[
        { required: true, message: 'Treść jest wymagana'},
      ]}},[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.addPost.content),callback:function ($$v) {_vm.$set(_vm.addPost, "content", $$v)},expression:"addPost.content"}})],1),_c('el-form-item',{attrs:{"id":"controlbuttons"}},[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.cancelForm()}}},[_vm._v("Anulować")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Dodać")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }